import React, {useContext} from "react";

// Custom components
import ActionsList from "./components/ActionsList";
import {usePageInfo} from "contexts/PageInfoContext";
import {ActionsDataContext, ActionsDataProvider} from "../../../contexts/actions/ActionsDataContext";
import {DataList} from "components/card/data/DataCard";
import {config} from "config/config";
import {Locale, useLocale} from "utils/Language";
import {useLayoutUpdate} from "contexts/layouts/LayoutContext";
import {BannerButton} from "components/card/Banner";

import {BiPlay} from "react-icons/bi";

export default function ActionsBoard() {
    const locale = useLocale()

    useLayoutUpdate({
        banner: {
            title: "通过仪表盘直接管理您的服务器",
            description: "灵活、方便的管理您的服务器，不用再输入指令了！",
            buttons: [config.tutorialUrl && <TutorialButton />]
        },
        dataList: config.data.actions && <ActionsDataProvider>
            <ActionsData />
        </ActionsDataProvider>
    })

    usePageInfo("管理面板");

    return <ActionsList />
}

function TutorialButton() {
    return <BannerButton
        leftIcon={<BiPlay size={20} />}
        url={config.tutorialUrl}
    >
        查看文档
    </BannerButton>
}

function ActionsData() {
    const data = useContext(ActionsDataContext)

    return <DataList items={config.data.actions(data)}/>
}