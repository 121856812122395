import React, {useContext} from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import NavAlert from "./NavAlert";
import {PageInfoContext} from "../../contexts/PageInfoContext";
import {useLocale} from "../../utils/Language";

export default function AdminNavbar() {
    const {info} = useContext(PageInfoContext)
    const locale = useLocale()

    const brandText = info ? info.name : "加载中..."

    return (
        <NavAlert rootText="页面" childText={brandText}>
            <AdminNavbarLinks/>
        </NavAlert>
    );
}