import {Locale} from "utils/Language";

export const CreaterAction = {
    name: "语音频道创建器",
    description: "用户进入创建器时自动创建新的临时语音频道",
    options: (values) => [
        {
            id: "name",
            name: "创建器名称",
            description: "语音频道创建器名称",
            type: "string",
            value: values? values.name : "➕ 创建语音频道",
            required: true
        },
        {
            id: "category_name",
            name: "分组名称",
            description: "语音频道创建器所在的分组名称",
            type: "string",
            value: values? values.category_name : "临时语音",
            required: true
        },
        {
            id: "channel_name",
            name: "临时频道名称",
            description: "为用户所创建的语音频道名称",
            type: "string",
            value: values? values.channel_name : "{NICKNAME} 的语音",
            required: true
        },
        {
            id: "limit_amount",
            name: "人数限制",
            description: "为用户所创建的语音频道的默认人数限制 (0 为无限制, 最高 99)",
            type: "number",
            value: values? values.limit_amount : 0,
            required: true
        },
        {
            id: "voice_quality",
            name: "声音质量",
            description: "为用户所创建的语音频道的默认声音质量",
            type: "id_enum",
            choices: [
                { name: "流畅", id: 1 },
                { name: "正常", id: 2 },
                { name: "高质量", id: 3 }
            ],
            value: values? values.voice_quality : 2,
        },
        {
            id: "name_change",
            name: "允许用户自行修改名称",
            description: "用户可以自行修改他们语音频道的名称",
            type: "boolean",
            value: values? values.name_change : true, 
        },
        {
            id: "limit_change",
            name: "允许用户自行修改人数限制",
            description: "用户可以自行更改他们频道的人数上限",
            type: "boolean",
            value: values? values.limit_change : true,
        },
        {
            id: "quality_change",
            name: "允许用户自行修改声音质量",
            description: "用户可以自行更改他们频道的声音质量",
            type: "boolean",
            value: values? values.quality_change : true,
        },
        {
            id: "id",
            name: "创建器频道 ID",
            description: "语音频道创建器频道 ID(留空自动生成)",
            type: "string",
            value: values? values.id : "",
        },
        {
            id: "category_id",
            name: "分组 ID",
            description: "语音频道创建器所在的分组 ID(留空自动生成)",
            type: "string",
            value: values? values.category_id : "",
        },
    ]
}
