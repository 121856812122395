import {dashboardData} from "./dashboard-data";
import {TempVoice} from "./features/tempvoice";
import {CreaterAction} from "./actions/Creater";

/**
 * @type ConfigType
 */
export const config = {
    name: "TempVoice",
    footer: [
        {
            name: "获取支持",
            url: "https://kook.top/PP2Dby"
        }
    ],
    settings: detail => [
        {
            id: "say",
            name: "没完事呢, 想写啥写啥罢",
            type: "string",
            value: detail["say"]
        }
    ],
    actions: {
        "creater": CreaterAction
    },
    features: {
        "tempvoice": TempVoice
    },
    tutorialUrl: "",
    serverUrl: "https://tcat.ncgs.top:20154",
    inviteUrl: "https://www.kookapp.cn/app/oauth2/authorize?id=13851&permissions=51231800&client_id=Yc_D002vsARZTTzP&redirect_uri=&scope=bot",
    data: {
        dashboard: dashboardData,
    },
}