import {fetchAuto} from "../utils";
import {useQuery} from "react-query";

export async function getAccountInfo() {
  return await fetchAuto(`/users/me`, {toJson: true})
}

export async function getGuilds() {
  return await fetchAuto(`/guilds`, {toJson: true})
}

export async function getGuild(id) {
  return await fetchAuto(`/guild/${id}`, {toJson: true})
}

export function bannerToUrl(id, hash) {
  return `https://img.kookapp.cn/banners/${id}/${hash}?size=1024`;
}
export function avatarToUrl(id, hash) {
  return `https://img.kookapp.cn/avatars/${id}/${hash}`;
}

export function iconToUrl(id, hash) {
  return `https://img.kookapp.cn/icons/${id}/${hash}`;
}

export function useGuild(id) {
  return useQuery(
      ["guild", id],
      () => getGuild(id),
      {
        refetchOnWindowFocus: false
      }
  )
}

export function useGuilds() {
  return useQuery(
      "user_guilds",
      () => getGuilds(),
      {
        refetchOnWindowFocus: false,
      }
  )
}

export async function fetchImage(url) {
  var response = await fetch(url, {
      headers: {
          // "Referer": "", // 这里设置无效
      },
      method: "GET", 
      mode: "no-cors",
      redirect: "follow",
      referrer: "",
      referrerPolicy: "no-referrer"
  })
  return URL.createObjectURL(response.blob())
}

