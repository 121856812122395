import React from "react";
// Chakra imports
import {Box, Button, Flex, Heading, Icon, Text,} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/Banner.jpg";
import {config} from "config/config";
import {Locale} from "../../../utils/Language";
import {useDetailColor, useTextColor} from "../../../utils/colors";

function SignIn({loading = false}) {

  // Chakra color mode

  const textColor = useTextColor();
  const textColorSecondary = useDetailColor();

  const onSignIn = () => {
    window.location.href = `${config.serverUrl}/login`
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            使用 KOOK 帐户登入
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            让您的 KOOK 服务器焕然一新
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Button
              variant="action"
            mb="26px"
            h="50px"
            color={textColor}
            fontWeight="bold"
            onClick={onSignIn}
            isLoading={loading}
          >
            <svg width="65px" height="20px" me="10px"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="widget" transform="translate(-20.000000, -747.000000)"><g id="logo" transform="translate(20.000000, 747.000000)"><polygon id="Fill-1" fill="#000000" points="3.58974572 0 0 16.8048513 1.59563961 19.7127201 37.36126 19.7127201 39.71304 24 42.0730968 24 42.9900428 19.7127201 53.2733163 19.7127201 56.8695652 2.88390443 55.2881143 0"></polygon><path d="M9.39035569,1.04347826 L8.62252662,4.76667691 L11.7992084,1.04347826 L16.173913,1.04347826 L15.7681686,3.01184666 L10.967345,8.64078381 L13.5869287,13.5909321 L13.269668,15.1304348 L9.26636207,15.1304348 L7.2655823,11.3499745 L6.48611064,15.1304348 L2.08695652,15.1304348 L4.99120158,1.04347826 L9.39035569,1.04347826 Z M46.433834,1.04347826 L45.666587,4.76667691 L48.8426866,1.04347826 L53.2173913,1.04347826 L52.8116469,3.01184666 L48.0108233,8.64078381 L50.6304069,13.5909321 L50.3131462,15.1304348 L46.3104225,15.1304348 L44.3090606,11.3499745 L43.5295889,15.1304348 L39.1304348,15.1304348 L42.0346798,1.04347826 L46.433834,1.04347826 Z M27.2715479,1.04347826 L28.173913,2.70153911 L25.9045275,13.4043815 L24.3896078,15.1304348 L15.0000998,15.1304348 L14.0869565,13.4526919 L16.3617311,2.7277818 L17.8389275,1.04347826 L27.2715479,1.04347826 Z M39.793287,1.04347826 L40.6956522,2.70153911 L38.4262666,13.4043815 L36.9119457,15.1304348 L27.5218389,15.1304348 L26.6086957,13.4526919 L28.8834703,2.7277818 L30.3606666,1.04347826 L39.793287,1.04347826 Z M22.5764945,5.67829511 L20.7052992,5.67829511 L19.6837763,10.4956179 L21.553774,10.4956179 L22.5764945,5.67829511 Z M35.0982336,5.67829511 L33.2276371,5.67829511 L32.2055155,10.4956179 L34.0755131,10.4956179 L35.0982336,5.67829511 Z" fill="#FFFFFF"></path></g></g></g></svg>
            使用 KOOK 登入
          </Button>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorSecondary} fontWeight="400" fontSize="14px">
              您的个人信息将被保密
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;