export const TempVoice = {
    name: "临时语音频道",
    description: "自动为用户创建临时语音频道",
    options: values => [
        {
            id: "name_change",
            name: "允许用户自行修改名称",
            description: "用户可以自行修改他们语音频道的名称",
            type: "boolean", //boolean, string, enum, number, color, message_create, array, id_enum, image
            choices: null, //only enum type option have choices array
            value: values?.["name_change"], //value must be nonnull in string, boolean, number type
        },
        {
            id: "limit_change",
            name: "允许用户自行修改人数限制",
            description: "用户可以自行更改他们频道的人数上限 (0 为无限制, 最高 99)",
            type: "boolean",
            choices: null,
            value: values?.["limit_change"],
        },
        {
            id: "quality_change",
            name: "允许用户自行修改声音质量",
            description: "用户可以自行更改他们频道的声音质量",
            type: "boolean",
            choices: null,
            value: values?.["quality_change"],
        }
    ]
}